body {
  text-shadow: 1px 1px 1px #00000001;
  color: #fff;
  background-color: #000;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  text-rendering: optimizelegibility !important;
  -webkit-font-smoothing: subpixel-antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

body, html, #root {
  height: 100%;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.gPMrEW_root {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.gPMrEW_routerWrapper {
  flex: 1;
  height: 100%;
}

.w9iSfa_root {
  background-color: #060ce9;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  display: flex;
}

.w9iSfa_title {
  vertical-align: middle;
  color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 10px 20px;
  display: flex;
}

.w9iSfa_titleText {
  text-align: center;
  flex-direction: column;
  font-size: 20px;
  display: flex;
}

.w9iSfa_titleName {
  font-weight: bold;
}

.w9iSfa_categories {
  flex-wrap: no-wrap;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  display: flex;
}

.w9iSfa_category {
  border: 1px solid gold;
  flex: 1;
}

.w9iSfa_categoryTitle {
  color: gold;
  text-align: center;
  text-transform: uppercase;
  height: 100px;
  padding: 10px;
  font-size: 30px;
  font-weight: bold;
}

.w9iSfa_answer {
  color: #fff;
  text-align: center;
  border-top: 1px solid gold;
  height: 100px;
  padding: 30px;
  font-size: 40px;
  font-weight: bold;
}

.w9iSfa_teamFooter {
  justify-content: space-evenly;
  width: 100%;
  display: flex;
}

.w9iSfa_teamItem {
  flex: 0 0 25%;
  align-items: center;
  padding: 10px;
  display: flex;
}

@keyframes w9iSfa_shadow-pulse {
  0% {
    box-shadow: 0 0 #fffc;
  }

  100% {
    box-shadow: 0 0 0 35px #fff0;
  }
}

.w9iSfa_teamItemActive {
  z-index: 10000;
  animation: 1s infinite w9iSfa_shadow-pulse;
}

.w9iSfa_titleFirst {
  font-size: 30px;
}

.w9iSfa_teamName {
  text-shadow: 1px 1px 1px #00000080;
  padding-left: 20px;
  font-size: 30px;
}

.w9iSfa_teamPoints {
  text-shadow: 1px 1px 1px #00000080;
  margin-left: 20px;
  font-size: 50px;
  font-weight: bold;
}

.w9iSfa_text {
  max-width: 800px;
  font-size: 60px;
}

.w9iSfa_code {
  text-align: left;
  font-size: 40px;
}

.w9iSfa_image {
  height: 100%;
  max-height: 600px;
}

.w9iSfa_categoryTitle {
  color: gold;
  text-align: center;
  vertical-align: middle;
  border-bottom: 2px solid gold;
  justify-content: center;
  align-items: center;
  padding: 30px;
  font-size: 30px;
  display: flex;
}

.w9iSfa_answerTitle {
  color: gold;
  text-align: center;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: bold;
}

.w9iSfa_confettiWrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.wXAiPW_root {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.wXAiPW_controlBar {
  background-color: gray;
  border-bottom: 2px solid gold;
  width: 100%;
  padding: 10px;
}

.wXAiPW_controlBar button {
  margin-right: 10px;
}

.wXAiPW_content {
  flex-grow: 1;
  height: 100%;
  display: flex;
}

.wXAiPW_categories {
  color: #fff;
  background-color: #00f;
  border-right: 2px solid gold;
  min-width: 400px;
  height: 100%;
  padding: 10px;
  overflow-y: scroll;
}

.wXAiPW_teams {
  flex-grow: 1;
}

.wXAiPW_rightColumn {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.wXAiPW_currentAnswer {
  color: #fff;
  background-color: #00f;
  flex-grow: 1;
}

.wXAiPW_team {
  padding: 10px;
}

.wXAiPW_teamName {
  font-size: 20px;
  font-weight: bold;
}

.wXAiPW_pointsbutton {
  margin-right: 10px;
  font-size: 20px;
}

.wXAiPW_currentAnswerTitle {
  font-size: 20px;
}

.wXAiPW_points {
  width: 50px;
  font-size: 18px;
  font-weight: 600;
}

.wXAiPW_answerRow {
  align-items: center;
  display: flex;
}

.wXAiPW_answerRow button {
  margin-left: 10px;
}

.wXAiPW_timer {
  color: #000;
  background-color: #fff;
  width: 100px;
  padding: 20px;
  font-size: 30px;
}

.wXAiPW_awardings {
  margin-top: 20px;
}

.jMgrDa_root {
  background-color: #000;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 10px;
  display: flex;
}

.jMgrDa_playerButton {
  border: none;
  width: 100%;
  height: 100%;
  font-size: 30px;
}

.jMgrDa_playerButton:active {
  opacity: .6;
}

.jMgrDa_teamInputWrapper {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.jMgrDa_teamInput {
  background-color: #0618bd;
  border: 4px solid #000;
  width: 100%;
  margin-bottom: 12px;
  padding: 12px 24px;
}

.jMgrDa_teamInput::placeholder {
  color: #c7f533;
  opacity: 1;
}

.jMgrDa_teamInputButton {
  color: #c7f533;
  background-color: #0618bd;
  border: 4px solid #000;
  width: 100%;
  padding: 12px 24px;
}

.jMgrDa_teamHeadline {
  font-size: 20px;
}

.jMgrDa_userId {
  opacity: .5;
  margin-bottom: 5px;
  font-size: 12px;
}

.jMgrDa_qrReader {
  text-align: center;
  width: 100%;
  max-width: 400px;
  max-height: 500px;
}

.jMgrDa_helpline {
  padding: 10px;
  font-size: 20px;
}

.jMgrDa_playerScreenInput {
  text-align: center;
  z-index: 9999;
  width: 100%;
  font-size: 30px;
  position: absolute;
  top: 50%;
}

.jMgrDa_playerScreenInput:focus {
  border: none;
  outline: none;
}

.jMgrDa_playerScreenInput:active {
  border: none;
}

.jMgrDa_loadingView {
  background-color: #060ce9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.jMgrDa_loadingView p {
  color: gold;
}

.jMgrDa_loader {
  width: 135px;
}

.jMgrDa_answerInfo {
  text-align: center;
  background-color: #060ce9;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  font-size: 20px;
}

/*# sourceMappingURL=index.4fc900be.css.map */
